<template>
<div>
  <v-card
    v-if="icon"
    :class="classes"
    class="ma-4 custom-WeAre-card"
    color="white"
  >
    <base-avatar
      v-if="icon"
      :color="color"
      :dark="dark"
      :icon="icon"
      :image="image"
      :outlined="outlined"
      :size="size"
      class="ma-6"
    />

    <div :class="horizontal && title && 'ml-6'">
      <base-title :title="title" class="text-uppercase ma-6" space="3" />

      <base-body
        v-if="text || $slots.default"
        :space="horizontal ? 0 : undefined"
        :text="text"
        class="ma-6"
        max-width="250"

      >
        <slot />
      </base-body>
    </div>
  </v-card>

  <v-card
    v-if="image"
    :class="classes"
    class="mx-auto mb-10 custom-card"
    max-width="400"
    elevation="1"
    hover
    rounded
    color="white"
  >
    <base-avatar
      v-if="image"
      :color="color"
      :dark="dark"
      :icon="icon"
      :image="image"
      :outlined="outlined"
      :size="size"
      class="mb-3 align-self-start"
    />

    <div :class="horizontal && title && 'ml-6'">
      
      <base-title :title="title" class="text-uppercase mt-5" space="3" />

    
    </div>
  </v-card>
</div>

</template>

<script>
// Mixins
import Heading from "@/mixins/heading";

export default {
  name: "BaseAvatarCard",

  mixins: [Heading],

  props: {
    align: {
      type: String,
      default: "left",
    },
    color: String,
    dark: Boolean,
    horizontal: Boolean,
    icon: String,
    image: String,
    outlined: {
      type: Boolean,
      default: true,
    },
    space: {
      type: [Number, String],
      default: 8,
    },
    size: {
      type: [Number, String],
      default: 72,
    },
    text: String,
    title: String,
  },

  computed: {
    classes() {
      const classes = [`mb-${this.space}`];

      if (this.horizontal) {
        classes.push("d-flex");

        if (!this.$slots.default && !this.text) {
          classes.push("align-center");
        }
      }

      return classes;
    },
  },
};
</script>
